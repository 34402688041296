import { useState, useRef, useEffect } from "react";
import useIsInViewport from "../../hooks/useIsInViewport";

export default function Animated(props) {
  const elementRef = useRef();
  const [isIn, setIsIn] = useState(false);
  const isInViewport = useIsInViewport(elementRef);

  useEffect(() => {
    if (isInViewport && !isIn) {
      setIsIn(true);
    } else {
      if (props.inAndOut) {
        setIsIn(false);
      }
    }
  }, [isInViewport, isIn, props.inAndOut]);

  return (
    <props.component
      ref={elementRef}
      {...props.componentProps}
      in={isIn}
      appear={true}
    >
      {props.children}
    </props.component>
  );
}

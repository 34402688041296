import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

export default function Footer() {
  return (
    <Container maxWidth="lg">
      <Box
        display="flex"
        justifyContent="space-between"
        flexWrap="wrap"
        sx={{
          paddingX: {
            md: 10,
          },
          flexDirection: {
            xs: "column",
            sm: "row",
          },
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          sx={{ marginBottom: { xs: 5, md: 10 }, marginRight: 3 }}
        >
          <Typography
            variant="h5"
            sx={{
              borderBottom: {
                xs: "solid 2px #f88202",
                sm: "solid 5px #f88202",
              },
              paddingBottom: {
                xs: 1,
              },
              marginBottom: {
                xs: 1,
                md: 2,
              },
              fontWeight: "bold",
            }}
          >
            Visit our shop{" "}
          </Typography>
          <Typography variant="h6">Phoenix Computer Systems</Typography>
          <Typography variant="h6">42 Roundstone Street</Typography>
          <Typography variant="h6">Trowbridge</Typography>
          <Typography variant="h6">BA14 8DE</Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          sx={{ marginBottom: { xs: 5, md: 10 }, marginRight: 3 }}
        >
          <Typography
            variant="h5"
            sx={{
              borderBottom: {
                xs: "solid 2px #60c2e0",
                sm: "solid 5px #60c2e0",
              },
              paddingBottom: {
                xs: 1,
              },
              marginBottom: {
                xs: 1,
                md: 2,
              },
              textTransform: "uppercase",
              fontWeight: "bold",
            }}
          >
            Give us a call
          </Typography>
          <Typography variant="h6">01225 774483</Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          sx={{ marginBottom: { xs: 5, md: 10 }, marginRight: 3 }}
        >
          <Typography
            variant="h5"
            sx={{
              borderBottom: {
                xs: "solid 2px white",
                sm: "solid 5px white",
              },
              paddingBottom: {
                xs: 1,
              },
              marginBottom: {
                xs: 1,
                md: 2,
              },
              textTransform: "uppercase",
              fontWeight: "bold",
            }}
          >
            Drop us an email
          </Typography>
          <Typography variant="h6">info@pcswilts.co.uk</Typography>
        </Box>
      </Box>
      <Box display="flex" justifyContent="center">
        <Typography variant="body1" sx={{ marginBottom: 5 }}>
          © {new Date().getFullYear()} Phoenix Computer Systems | All Rights
          Reserved
        </Typography>
      </Box>
    </Container>
  );
}

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import EmailIcon from "@mui/icons-material/Email";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import Zoom from "@mui/material/Zoom";
import styled from "@mui/system/styled";
import logo from "../../../images/logo.png";
import Animated from "../../../components/Animated";
import { Typography } from "@mui/material";

const Logo = styled("img")({
  maxWidth: 340,
});

const DotSpacer = styled("span")(() => ({
  fontStyle: "normal",
  height: 10,
  color: "#fc8007",
  "&:before": {
    content: '"• "',
    fontSize: 34,
    lineHeight: 0,
  },
}));

const ContactIcon = styled("a")(({ theme }) => ({
  marginLeft: 20,
  [theme.breakpoints.up("md")]: {
    marginLeft: 40,
  },
}));

const ContactLink = styled("a")(({ theme }) => ({
  textDecoration: "none",
  color: "white",
}));

export default function Header() {
  return (
    <Box>
      <Container maxWidth="lg">
        <Box
          display="flex"
          justifyContent="space-between"
          sx={{
            paddingTop: 3,
            marginBottom: {
              xs: 8,
              md: 10,
            },
            flexDirection: {
              xs: "column",
              sm: "row",
            },
            alignItems: {
              xs: "center",
            },
          }}
        >
          <Box
            sx={{
              marginBottom: {
                xs: 10,
                md: 0,
              },
            }}
          >
            <a href="/">
              <Logo src={logo} alt="logo" />
            </a>
            <Typography
              variant="h6"
              sx={{
                color: (theme) => theme.palette.primary.main,
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              Est. 2003
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column">
            <Box display="flex" alignItems="center" position="relative">
              <Box
                sx={{
                  fontSize: "1.3rem",
                  fontWeight: "bold",
                  borderBottom: "solid 3px #ff8002",
                  marginTop: "-10px",
                }}
              >
                <ContactLink href="tel:01225774483">01225 774483</ContactLink>
              </Box>
              <ContactIcon href="mailto:info@pcswilts.co.uk">
                <EmailIcon
                  sx={{
                    fontSize: "2.5rem",
                    color: (theme) => theme.palette.text.primary,
                  }}
                />
              </ContactIcon>
              <ContactIcon
                href="https://www.instagram.com/phoenixcomputersystems/"
                rel="noreferrer"
                target="_blank"
              >
                <InstagramIcon
                  sx={{
                    fontSize: "2.5rem",
                    color: "#fdf497",
                  }}
                />
              </ContactIcon>
              <ContactIcon
                href="https://www.facebook.com/pcswilts/"
                rel="noreferrer"
                target="_blank"
              >
                <Animated component={Zoom} componentProps={{ timeout: 700 }}>
                  <Box
                    sx={{
                      position: "absolute",
                      top: -50,
                      right: 0,
                      display: "inline-block",
                      background: "#1a77f2",
                      color: "white",
                      borderRadius: 2,
                      fontWeight: "bold",
                      padding: "5px 30px",
                      "&::before": {
                        background: "#1a77f2",
                        bottom: -5,
                        content: '" "',
                        display: "block",
                        height: 13,
                        right: 11,
                        position: "absolute",
                        transform: "rotate(-63deg) skew(-35deg)",
                        width: 15,
                      },
                    }}
                  >
                    See our work!
                  </Box>
                </Animated>
                <FacebookIcon sx={{ fontSize: "2.5rem", color: "#1a77f2" }} />
              </ContactIcon>
            </Box>
            <Box
              display="flex"
              sx={{ fontStyle: "italic", mt: 1 }}
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="h6" sx={{ mx: 2 }}>
                fast
              </Typography>
              <DotSpacer />
              <Typography variant="h6" sx={{ mx: 2 }}>
                onsite
              </Typography>
              <DotSpacer />
              <Typography variant="h6" sx={{ mx: 2 }}>
                affordable
              </Typography>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

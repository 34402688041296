import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Home from "./views/Home";
import { BrowserRouter } from "react-router-dom";

let theme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: "#251f1f",
      paper: "#332c2c",
    },
    text: {
      primary: "#fff4ea",
      secondary: "#60c2e0",
    },
    primary: {
      main: "#f88202",
    },
    secondary: {
      main: "#60c2e0",
    },
  },
});

theme = responsiveFontSizes(theme);

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Home />
        </ThemeProvider>
      </div>
    </BrowserRouter>
  );
}

export default App;

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Slide from "@mui/material/Slide";
import Fade from "@mui/material/Fade";
import styled from "@mui/system/styled";
import Animated from "../../../components/Animated";
import Highlight from "../../../components/Highlight";
import PhoenixOutline from "../../../images/phoenix-outline.png";
import PhoenixGlow from "../../../images/phoenix-glow.png";

const Phoenix = styled("img")({
  position: "absolute",
  right: 0,
  zIndex: -1,
});

export default function Hero() {
  return (
    <Box
      sx={{
        marginBottom: {
          xs: 4,
          md: 12,
        },
      }}
    >
      <Container maxWidth="lg">
        <Box display="flex" sx={{ flexDirection: { xs: "column", md: "row" } }}>
          <Box display="inline-block">
            <Box
              display="inline-flex"
              flexDirection="column"
              alignItems="center"
              flexGrow={1}
            >
              <Animated
                component={Slide}
                componentProps={{ direction: "right", timeout: 700 }}
              >
                <Typography
                  variant="h1"
                  sx={{
                    color: (theme) => theme.palette.text.secondary,
                    marginBottom: 1,
                  }}
                  fontWeight="bold"
                >
                  computer experts<Highlight>.</Highlight>
                </Typography>
              </Animated>
              <Animated component={Fade} componentProps={{ timeout: 1500 }}>
                <Box>
                  <Typography variant="h5" gutterBottom component="div">
                    Cost effective, friendly and professional
                  </Typography>
                  <List>
                    {[
                      "Apple Computer Repairs",
                      "PC & Laptop Repairs",
                      "Mobile & Tablet Repairs",
                      "AV Cables & Accessories",
                      "Mobile Phone Unlocking",
                      "VHS To DVD Transfer",
                    ].map((svc, i) => (
                      <ListItem key={`service-${i}`}>
                        <ListItemIcon>
                          <CheckCircleIcon
                            sx={{
                              fontSize: (theme) => theme.typography.h3.fontSize,
                              color: "#ff8002",
                            }}
                          />
                        </ListItemIcon>
                        <Typography
                          sx={{
                            fontSize: (theme) => theme.typography.h5.fontSize,
                          }}
                        >
                          {svc}
                        </Typography>
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </Animated>
            </Box>
          </Box>
          <Box
            flexGrow={1}
            sx={{
              position: "relative",
              display: {
                xs: "none",
                md: "block",
              },
            }}
          >
            <Phoenix
              src={PhoenixOutline}
              sx={{
                maxWidth: {
                  md: 450,
                  lg: 520,
                },
                top: {
                  md: 0,
                  lg: -80,
                },
              }}
            />
            <Phoenix
              src={PhoenixGlow}
              className="flicker-phoenix"
              sx={{
                maxWidth: {
                  md: 450,
                  lg: 520,
                },
                top: {
                  md: 0,
                  lg: -80,
                },
              }}
            />
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

import { useEffect, useState, useMemo } from "react";

const isRefIn = (ref) => {
  if (!ref) return false;
  const { top } = ref.current.getBoundingClientRect();
  return window.innerHeight - top > 20;
};

export default function useIsInViewport(ref) {
  const [isInViewPort, setIsInViewport] = useState(false);

  useEffect(() => {
    if (ref) {
      setIsInViewport(isRefIn(ref));
    }
  }, [ref]);

  useEffect(() => {
    function handleScroll() {
      return setIsInViewport(isRefIn(ref));
    }

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [ref, isInViewPort]);

  return isInViewPort;
}

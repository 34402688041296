import { useMemo, useState } from "react";
import { Routes, Route, Link, useParams, useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Slide from "@mui/material/Slide";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Fade } from "@mui/material";
import Animated from "../../../components/Animated";
import { issues, consoles, descriptions } from "./config";

const Highlight = styled("span")(({ theme }) => ({
  color: theme.palette.primary.main,
}));

const AltHighlight = styled("span")(({ theme }) => ({
  color: theme.palette.secondary.main,
}));

const categories = [
  { id: "games-console", label: "Games Console", pathSuffix: "/type" },
  { id: "iphone", label: "iPhone" },
  { id: "ipad", label: "iPad" },
  { id: "tablet", label: "Tablet" },
  { id: "apple-laptop", label: "Apple Laptop" },
  { id: "apple-computer", label: "Apple Computer" },
  { id: "windows-laptop", label: "Windows Laptop" },
  { id: "windows-computer", label: "Windows Computer" },
  { id: "other", label: "Other electronic device" },
];

function Category() {
  return (
    <Box
      sx={{
        paddingX: { xs: "15px", md: "40px" },
        borderLeft: "solid 10px #5fc2e0",
      }}
    >
      <Typography variant="h4" fontWeight="bold" sx={{ marginBottom: 4 }}>
        Let us know what you need help with:
      </Typography>
      <Box display="inline-block">
        <Box
          display="flex"
          flexWrap="wrap"
          sx={{
            flexDirection: {
              xs: "row",
            },
          }}
        >
          {categories.map((c) => (
            <Link
              key={c.id}
              to={`/help/${c.id}${c.pathSuffix || ""}`}
              style={{ textDecoration: "none" }}
            >
              {" "}
              <Button
                variant="outlined"
                color="secondary"
                sx={{
                  marginBottom: 2,
                  marginRight: { xs: 1, md: 2 },
                  border: "solid white 3px",
                  borderRadius: 20,
                  padding: {
                    xs: "6px 10px",
                    md: "10px 20px",
                  },
                  textAlign: "center",
                  color: "white",
                  fontSize: {
                    xs: "1rem",
                    md: "1.2rem",
                  },
                  "&:hover": {
                    borderWidth: 3,
                  },
                }}
              >
                {c.label}{" "}
              </Button>
            </Link>
          ))}
        </Box>
      </Box>
    </Box>
  );
}

function DeviceType() {
  return (
    <Box
      sx={{
        paddingX: { xs: "15px", md: "40px" },
        borderLeft: "solid 10px #5fc2e0",
      }}
    >
      <Typography variant="h4" fontWeight="bold" sx={{ marginBottom: 4 }}>
        Which console?
      </Typography>
      <Box display="inline-block">
        <Box display="flex" flexDirection="row" flexWrap="wrap">
          {consoles.map((c) => (
            <Link key={c.id} to={`${c.id}`} style={{ textDecoration: "none" }}>
              {" "}
              <Button
                variant="outlined"
                color="secondary"
                sx={{
                  marginBottom: 2,
                  marginRight: { xs: 1, md: 2 },
                  border: "solid white 3px",
                  borderRadius: 20,
                  padding: {
                    xs: "6px 10px",
                    md: "10px 20px",
                  },
                  textAlign: "center",
                  color: "white",
                  fontSize: {
                    xs: "1rem",
                    md: "1.2rem",
                  },
                  "&:hover": {
                    borderWidth: 3,
                  },
                }}
              >
                {c.label}{" "}
              </Button>
            </Link>
          ))}
        </Box>
      </Box>
    </Box>
  );
}

function Issue() {
  const params = useParams();

  const questions = useMemo(() => {
    const device = params.type || params.category;
    return issues.find((i) => i.devices.some((d) => d === device)).options;
  }, [params]);

  return (
    <Box
      sx={{
        paddingX: { xs: "15px", md: "40px" },
        borderLeft: "solid 10px #5fc2e0",
      }}
    >
      <Typography variant="h4" fontWeight="bold" sx={{ marginBottom: 4 }}>
        What's the problem?
      </Typography>
      <Box display="inline-block">
        <Box display="flex" flexDirection="row" flexWrap="wrap">
          {questions.map((c) => (
            <Link
              key={c.id}
              to={`issue/${c.id}`}
              style={{ textDecoration: "none" }}
            >
              {" "}
              <Button
                variant="outlined"
                color="secondary"
                sx={{
                  marginBottom: 2,
                  marginRight: { xs: 1, md: 2 },
                  border: "solid white 3px",
                  borderRadius: 20,
                  padding: {
                    xs: "6px 10px",
                    md: "10px 20px",
                  },
                  textAlign: "center",
                  color: "white",
                  fontSize: {
                    xs: "1rem",
                    md: "1.2rem",
                  },
                  "&:hover": {
                    borderWidth: 3,
                  },
                }}
              >
                {c.label}{" "}
              </Button>
            </Link>
          ))}
        </Box>
      </Box>
    </Box>
  );
}

const FormTextField = ({
  id,
  label,
  value,
  required,
  interacted,
  onChange,
  inputProps,
}) => (
  <TextField
    id={id}
    sx={{
      width: "100%",
      marginBottom: 3,
      "& label": {
        background: "transparent !important",
      },
      "& fieldset": { borderColor: "white" },
    }}
    inputProps={{
      sx: {
        "&:-webkit-autofill": {
          WebkitBoxShadow: "0 0 0 1000px #3e3e3e inset",
        },
        "&:-internal-autofill-selected": {
          WebkitBoxShadow: "0 0 0 1000px #3e3e3e inset",
        },
      },
    }}
    error={required && !value && interacted}
    label={label}
    value={value}
    variant="outlined"
    onChange={onChange}
    {...inputProps}
  />
);

function ContactInfo(props) {
  const params = useParams();
  const navigate = useNavigate();

  const device = useMemo(() => {
    if (params.type) {
      return consoles.find((d) => d.id === params.type).label;
    }
    return categories.find((c) => c.id === params.category).label;
  }, [params]);
  const issue = useMemo(() => {
    const device = params.type || params.category;
    return issues
      .find((i) => i.devices.some((d) => d === device))
      .options.find((o) => o.id === params.issue).label;
  }, [params]);

  const [fields, setFields] = useState({
    name: "",
    number: "",
    email: "",
    message: "",
  });

  const [interactions, setInteractions] = useState({
    name: false,
    email: false,
  });

  const onChange = (field) => (e) => {
    setFields({ ...fields, [field]: e.target.value });
    setInteractions({ ...interactions, [field]: true });
  };

  const onSubmit = () => {
    window.emailjs
      .send("service_fuqw3v6", "template_3abuvz9", {
        ...fields,
        device,
        issue,
      })
      .then(() => {
        props.onCompleted();
        navigate("/");
        window.scrollTo(0, 0);
      });
  };

  return (
    <Box
      sx={{
        paddingX: { xs: "25px", md: "40px" },
        borderLeft: "solid 10px #5fc2e0",
      }}
    >
      <Typography variant="h4" fontWeight="bold" sx={{ marginBottom: 4 }}>
        Contact information
      </Typography>
      <Box display="inline-block" sx={{ width: "100%", maxWidth: 600 }}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <FormTextField
            id="name"
            value={fields.name}
            interacted={interactions.name}
            required
            label="Your name"
            onChange={onChange("name")}
          />
          <FormTextField
            id="email"
            value={fields.email}
            interacted={interactions.email}
            required
            label="Email address"
            onChange={onChange("email")}
          />
          <FormTextField
            id="number"
            value={fields.number}
            label="Contact number"
            onChange={onChange("number")}
          />
          <FormTextField
            id="info"
            value={fields.message}
            label="Additional information"
            onChange={onChange("message")}
            inputProps={{
              multiline: true,
              rows: 4,
            }}
          />
          <Button
            variant="contained"
            disabled={!(fields.name && fields.email)}
            sx={{
              fontSize: "1.2rem",
              color: "#5f3002",
              fontWeight: "bold",
              paddingX: 5,
              paddingY: 1,
            }}
            onClick={onSubmit}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

const GetInTouch = () => (
  <Animated
    component={Slide}
    componentProps={{ direction: "right", timeout: 700 }}
  >
    <Typography variant="h1" fontWeight="bold" sx={{ marginBottom: 3 }}>
      Get in touch...
    </Typography>
  </Animated>
);

const NeedHelpWithYour = () => {
  const params = useParams();
  const device = useMemo(() => {
    if (params.type) {
      return consoles.find((d) => d.id === params.type).label;
    }
    return categories.find((c) => c.id === params.category).label;
  }, [params]);

  const description = useMemo(
    () =>
      (
        descriptions.find((d) =>
          d.devices.includes(params.type || params.category)
        ) || {}
      ).description,
    [params]
  );

  return (
    <Animated component={Fade} componentProps={{ timeout: 1500 }}>
      <Box
        display="flex"
        flexDirection="column"
        sx={{ mb: description ? 0 : 4 }}
      >
        <Typography
          variant="h3"
          fontWeight="bold"
          sx={{ mb: description ? 4 : 0 }}
        >
          I'm looking for help with my <AltHighlight>{device}</AltHighlight>...
        </Typography>
        {description && (
          <>
            <Box
              sx={{
                padding: 4,
                background: "#3d3737",
              }}
            >
              {description}
            </Box>
            <Typography variant="h3" fontWeight="bold" sx={{ mt: 6, mb: 4 }}>
              Get in touch...
            </Typography>
          </>
        )}
      </Box>
    </Animated>
  );
};

const TheIssueIs = () => {
  const params = useParams();
  const device = useMemo(() => {
    if (params.type) {
      return consoles.find((d) => d.id === params.type).label;
    }
    return categories.find((c) => c.id === params.category).label;
  }, [params]);
  const issue = useMemo(() => {
    const device = params.type || params.category;
    return issues
      .find((i) => i.devices.some((d) => d === device))
      .options.find((o) => o.id === params.issue).header;
  }, [params]);
  return (
    <Animated component={Fade} componentProps={{ timeout: 1500 }}>
      <Typography
        component="span"
        variant="h3"
        fontWeight="bold"
        sx={{ marginBottom: 6 }}
      >
        I'm looking for help with my <AltHighlight>{device}</AltHighlight>{" "}
        {issue}...
      </Typography>
    </Animated>
  );
};

export default function Form() {
  const [completed, setCompleted] = useState(false);

  const onCompleted = () => setCompleted(true);

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        marginBottom: {
          xs: 6,
          md: 12,
        },
      }}
    >
      {completed ? (
        <Animated component={Fade} componentProps={{ timeout: 1500 }}>
          <Typography
            variant="h2"
            fontWeight="bold"
            sx={{ marginY: 10, textDecoration: "underline", maxWidth: 1000 }}
          >
            <AltHighlight>Thank you</AltHighlight> for submitting your enquiry,{" "}
            <AltHighlight>we'll be in touch soon!</AltHighlight>
          </Typography>
        </Animated>
      ) : (
        <>
          <Box display="flex">
            <Routes>
              <Route path="/" element={<GetInTouch />} />
              <Route path="/help/:category/type/*" element={<GetInTouch />} />
              <Route path="/help/:category" element={<NeedHelpWithYour />} />
              <Route
                path="/help/:category/type/:type"
                element={<NeedHelpWithYour />}
              />
              <Route
                path="/help/:category/issue/:issue"
                element={<TheIssueIs />}
              />
              <Route
                path="/help/:category/type/:type/issue/:issue"
                element={<TheIssueIs />}
              />
            </Routes>
          </Box>
          <Box>
            <Routes>
              <Route path="/" element={<Category />} />
              <Route path="/help/:category/type" element={<DeviceType />} />
              <Route path="/help/:category" element={<Issue />} />
              <Route path="/help/:category/type/:type" element={<Issue />} />
              <Route
                path="/help/:category/issue/:issue"
                element={<ContactInfo onCompleted={onCompleted} />}
              />
              <Route
                path="/help/:category/type/:type/issue/:issue"
                element={<ContactInfo onCompleted={onCompleted} />}
              />
            </Routes>
          </Box>
        </>
      )}
    </Box>
  );
}

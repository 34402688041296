import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Slide from "@mui/material/Slide";
import Fade from "@mui/material/Fade";
import Animated from "../../../components/Animated";

export default function Why() {
  return (
    <Box
      sx={{
        marginBottom: {
          xs: 4,
          md: 12,
        },
      }}
    >
      <Container maxWidth="lg">
        <Box display="flex" flexDirection="column">
          <Box
            display="flex"
            flexDirection="column"
            xs={{
              marginBottom: {
                xs: 4,
                md: 8,
              },
            }}
          >
            <Animated
              component={Slide}
              componentProps={{ direction: "right", timeout: 700 }}
            >
              <Box>
                <Typography
                  variant="h2"
                  fontWeight="bold"
                  sx={{
                    display: "inline-block",
                    background: (theme) => theme.palette.secondary.main,
                    color: "#025771",
                    marginBottom: 5,
                    paddingY: 2,
                    paddingX: 5,
                    minWidth: "50%",
                    position: "relative",
                  }}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      background: (theme) => theme.palette.secondary.main,
                      height: "100%",
                      width: 10000,
                      top: 0,
                      right: "100%",
                    }}
                  />
                  fast turn around
                </Typography>
              </Box>
            </Animated>
            <Animated component={Fade} componentProps={{ timeout: 1500 }}>
              <Box>
                <Typography
                  variant="h5"
                  sx={{
                    marginBottom: 2,
                    maxWidth: 450,
                    fontWeight: "bold",
                  }}
                >
                  Local repairs we aim to turnaround in 2-3 working days.
                  National repairs 3-4 working days including days of collection
                  and return delivery.
                </Typography>
              </Box>
            </Animated>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            sx={{
              alignSelf: "flex-end",
              minWidth: "50%",
              marginBottom: {
                xs: 4,
                md: 8,
              },
            }}
          >
            <Animated
              component={Slide}
              componentProps={{ direction: "left", timeout: 700 }}
            >
              <Box>
                <Typography
                  variant="h2"
                  fontWeight="bold"
                  sx={{
                    background: (theme) => theme.palette.primary.main,
                    color: "#5f3002",
                    marginBottom: 5,
                    paddingY: 2,
                    paddingX: 5,
                    position: "relative",
                  }}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      background: (theme) => theme.palette.primary.main,
                      height: "100%",
                      width: 10000,
                      top: 0,
                      left: "100%",
                    }}
                  />
                  90 day guarantee
                </Typography>
              </Box>
            </Animated>
            <Animated component={Fade} componentProps={{ timeout: 1500 }}>
              <Box>
                <Typography
                  variant="h5"
                  sx={{
                    marginBottom: 2,
                    maxWidth: 450,
                    fontWeight: "bold",
                  }}
                >
                  All work is guaranteed for at least 90 days (excluding
                  software) - please ask for exact details of your repair.
                </Typography>
              </Box>
            </Animated>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

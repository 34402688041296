import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Header from "./Header";
import Form from "./Form";
import Hero from "./Hero";
import About from "./About";
import Services from "./Services";
import Why from "./Why";
import Footer from "./Footer";

function Home() {
  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{ maxWidth: "100vw", overflow: "hidden" }}
    >
      <Header />
      <Box display="flex" flexDirection="column" sx={{ padding: "20px 0" }}>
        <Box>
          <Container maxWidth="lg">
            <Form />
          </Container>
        </Box>
      </Box>
      <Hero />
      <About />
      <Services />
      <Why />
      <Footer />
    </Box>
  );
}

export default Home;

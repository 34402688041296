import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Slide from "@mui/material/Slide";
import Fade from "@mui/material/Fade";
import Animated from "../../../components/Animated";

export default function Services() {
  return (
    <Box sx={{
        marginBottom: {
          xs: 8,
          md: 12,
        },
      }}>
      <Container maxWidth="lg">
        <Animated
          component={Slide}
          componentProps={{ direction: "right", timeout: 700 }}
        >
          <Typography
            variant="h1"
            fontWeight="bold"
            sx={{
              display: "inline-block",
              marginBottom: 5,
            }}
          >
            services we offer
          </Typography>
        </Animated>
        <Grid container spacing={6}>
          <Grid item xs={12} sm={7} md={4}>
            <Animated component={Fade} componentProps={{ timeout: 1000 }}>
              <Card sx={{ height: "100%" }}>
                <Typography
                  variant="h5"
                  sx={{
                    background: "#5fc2e0",
                    color: "#025771",
                    fontWeight: "bold",
                    padding: "10px 35px",
                  }}
                >
                  PC / Windows
                </Typography>
                <Box paddingTop={4}>
                  {[
                    "Health checks",
                    "Diagnosis and repair",
                    "Software and O/S installs",
                    "Component upgrades",
                    "Bespoke system design",
                    "Data recovery",
                    "Data backup",
                    "Laptop repairs",
                    "Screen replacement",
                    "Peripherals",
                    "Onsite servicing options",
                  ].map((svc, i) => (
                    <Typography
                      key={`pc-windows-${i}`}
                      sx={{
                        padding: "0 20px",
                        borderLeft: "solid 15px #251f1f",
                        lineHeight: "4px",
                        marginBottom: 4,
                      }}
                      fontSize="1.2rem"
                    >
                      {svc}
                    </Typography>
                  ))}
                </Box>
              </Card>
            </Animated>
          </Grid>
          <Grid item xs={12} sm={7} md={4}>
            <Animated component={Fade} componentProps={{ timeout: 2000 }}>
              <Card sx={{ height: "100%" }}>
                <Typography
                  variant="h5"
                  sx={{
                    background: "#ff8001",
                    color: "#5f3002",
                    fontWeight: "bold",
                    padding: "10px 35px",
                  }}
                >
                  iMac & Macbook
                </Typography>
                <Box paddingTop={4}>
                  {[
                    "Health checks",
                    "Diagnosis and repair",
                    "Replacement LCD glass",
                    "Logic board repairs",
                    "OS upgrades",
                    "Data recovery",
                    "Data backup",
                    "Replacement screens / LCDs",
                    "Replacement hard drives",
                    "Replacement power supplies",
                    "Replacement keyboard",
                    "Peripherals",
                  ].map((svc, i) => (
                    <Typography
                      key={`mac-${i}`}
                      sx={{
                        padding: "0 20px",
                        borderLeft: "solid 15px #251f1f",
                        lineHeight: "4px",
                        marginBottom: 4,
                      }}
                      fontSize="1.2rem"
                    >
                      {svc}
                    </Typography>
                  ))}
                </Box>
              </Card>
            </Animated>
          </Grid>
          <Grid item xs={12} sm={7} md={4}>
            <Animated component={Fade} componentProps={{ timeout: 3500 }}>
              <Card sx={{ height: "100%" }}>
                <Typography
                  variant="h5"
                  sx={{
                    background: "#5fc2e0",
                    color: "#025771",
                    fontWeight: "bold",
                    padding: "10px 35px",
                  }}
                >
                  Other
                </Typography>
                <Box paddingTop={4}>
                  {[
                    "Mobile phone repairs",
                    "iPad and tablet repairs",
                    "VHS to DVD transfer",
                    "Mobile phone unlocks",
                    "AV cables and accessories",
                    "TV cables",
                    "USB memory sticks",
                    "Memory cards",
                    "Network components",
                  ].map((svc, i) => (
                    <Typography
                      key={`other-${i}`}
                      sx={{
                        padding: "0 20px",
                        borderLeft: "solid 15px #251f1f",
                        lineHeight: "4px",
                        marginBottom: 4,
                      }}
                      fontSize="1.2rem"
                    >
                      {svc}
                    </Typography>
                  ))}
                </Box>
              </Card>
            </Animated>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

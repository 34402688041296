import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Slide from "@mui/material/Slide";
import Fade from "@mui/material/Fade";
import Animated from "../../../components/Animated";
import Highlight from "../../../components/Highlight";

export default function About() {
  return (
    <Box
      sx={{
        marginBottom: {
          xs: 4,
          md: 12,
        },
      }}
    >
      <Container maxWidth="lg">
        <Box display="flex" sx={{ flexDirection: { xs: "column", md: "row" } }}>
          <Box
            sx={{
              paddingRight: { xs: 0, md: 10 },
              marginBottom: { xs: 6, md: 0 },
            }}
          >
            <Animated
              component={Slide}
              componentProps={{ direction: "right", timeout: 700 }}
            >
              <Typography
                variant="h1"
                fontWeight="bold"
                sx={{
                  display: "inline-block",
                  borderBottom: "solid 10px #5fc2e0",
                  marginBottom: 5,
                }}
              >
                about us
              </Typography>
            </Animated>
            <Animated component={Fade} componentProps={{ timeout: 1500 }}>
              <Box>
                <Typography
                  variant="h5"
                  sx={{
                    marginBottom: 2,
                  }}
                >
                  <Highlight>Phoenix Computer Systems</Highlight> is your local,
                  friendly, straight-talking computer outlet based in
                  Trowbridge, Wiltshire. Our number one goal is customer
                  satisfaction, not sales targets or shifting stock.
                </Typography>

                <Typography variant="h5">
                  We've been trading for over 19 years and our computer
                  experts have a wealth of experience diagnosing and repairing
                  all manners of computing faults and issues.
                </Typography>
              </Box>
            </Animated>
          </Box>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9974.183709465498!2d-2.2071391!3d51.3194778!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe1b46d4905078440!2sPhoenix%20Computer%20Systems!5e0!3m2!1sen!2suk!4v1643485477730!5m2!1sen!2suk"
            width="100%"
            height="auto"
            style={{ border: 0, minHeight: 300 }}
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </Box>
      </Container>
    </Box>
  );
}
